<template>
    <form @submit.prevent="submit" class="styled-form">

      <!-- Ticketnumber -->
      <div class="form-row">
        <label for="ticketnumber">{{ $t("Ticket number") }}</label>
        <input v-model="$v.ticketnumber.$model" :disabled="formLoading" type="text" name="ticketnumber" id="ticketnumber">
        <span class="error" v-if="$v.ticketnumber.$dirty && !$v.ticketnumber.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Email -->
      <div class="form-row">
        <label for="email">{{ $t("Email address") }}</label>
        <input v-model="$v.email.$model" :disabled="formLoading" type="email" name="email" id="email">
        <span class="error" v-if="$v.email.$dirty && !$v.email.required">{{ $t("Field is required") }}</span>
        <span class="error" v-else-if="$v.email.$dirty && !$v.email.email">{{ $t("This is not a correct email address") }}</span>
      </div>

      <!-- Name -->
      <div class="form-row">
        <label for="name">{{ $t("Name") }}</label>
        <input v-model="$v.name.$model" :disabled="formLoading" type="text" name="name" id="name">
        <span class="error" v-if="$v.name.$dirty && !$v.name.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- IBAN -->
      <div class="form-row">
        <label for="iban">{{ $t("IBAN") }}</label>
        <input v-model="$v.iban.$model" :disabled="formLoading" type="text" name="iban" id="iban">
        <span class="error" v-if="$v.iban.$dirty && !$v.iban.required">{{ $t("Field is required") }}</span>
        <span class="error" v-if="$v.iban.$dirty && !$v.iban.iban && $v.iban.required">{{ $t("This is not a correct IBAN") }}</span>
      </div>

       <!-- GDPR -->
      <div class="form-row checkbox">
        <input v-model="$v.gdprConsent.$model" type="checkbox" :disabled="formLoading" name="gdpr" id="gdpr">
        <label for="gdpr">{{ $t("I have read and understood the") }} <router-link :to="{name: 'Privacy'}" target="_blank">{{ $t("Privacy Statement") }}</router-link> {{ $t("and I agree to the") }} <router-link :to="{name: 'TermsOfUse'}" target="_blank">{{ $t("Terms of Use") }}</router-link></label>
        <span class="error" v-if="$v.gdprConsent.$dirty && !$v.gdprConsent.required">{{ $t("Field is required") }}</span>
      </div>

      <!-- Recaptcha -->
      <div class="form-row">
         <vue-recaptcha sitekey="6LeORZccAAAAAO-S_MMoHiHOjfD5pS2G2uPUDYzo" ref="recaptcha" @verify="onVerifyRecaptcha" @expired="recaptcha = ''"></vue-recaptcha>
         <span class="error" v-if="$v.recaptcha.$dirty && !$v.recaptcha.required">{{ $t("Field is required") }}</span>
      </div>

      <div class="form-row" v-if="formErrors.length > 0 && !$v.$anyDirty">
        <span class="error" v-for="error in formErrors" :key="error.description">{{ $t(error.description) }}</span>
      </div>

      <div class="form-row">
        <input type="submit" :disabled="formLoading" class="btn btn-primary" :class="formLoading?'loading':''" :value="$t('Submit form')">
      </div>

      <div class="form-row">
        <p>{{ $t("Don't agree with the refund proposition") }}? <router-link :to="{ name: 'Reject' }">{{ $t("Reject request here") }}</router-link></p>
      </div>

    </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import ApiService from '@/services/api.service'
import router from '@/router/'

import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RefundForm',
  props: {
    guid: String
  },
  components: {
    VueRecaptcha
  },
  data () {
    return {
      ticketnumber: null,
      email: '',
      name: '',
      iban: '',
      recaptcha: '',
      gdprConsent: false,
      formLoading: false,
      formErrors: []
    }
  },
  validations: {
    ticketnumber: { required },
    iban: {
      required,
      iban (value) {
        if (value === null) return true
        if (/^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i.test(value)) { return true } else { return false }
      }
    },
    name: { required },
    email: { required, email },
    recaptcha: { required },
    gdprConsent: {
      required: function (value) {
        if (value) {
          return true
        } else {
          return false
        }
      }
    }
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {
    onVerifyRecaptcha: function (response) {
      this.recaptcha = response
    },
    submit () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()

        ApiService.post('/Recaptcha', {
          token: this.recaptcha
        })
          .then(response => {
            if (response.data.success && response.data.data.result.valid) {
              this.refund()
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
      }
    },
    refund () {
      this.formLoading = true
      this.formError = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()

        ApiService.put('/Tickets/Refund', {
          Id: parseInt(this.ticketnumber),
          Guid: this.guid,
          Email: this.email,
          Name: this.name,
          Iban: this.iban,
          GdprConsent: this.gdprConsent
        })
          .then(response => {
            if (response.data.success) {
              this.$v.$reset()
              router.push({ name: 'RefundSuccess' })
            } else if (response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
          .catch(error => {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              this.formLoading = false
              this.formErrors = error.response.data.errors
            } else {
              this.formLoading = false
              this.formErrors = [{ description: 'Something went wrong. Please try again.' }]
            }
            this.$refs.recaptcha.reset()
          })
      } else {
        this.formLoading = false
        this.$refs.recaptcha.reset()
      }
    }
  },
  watch: {}
}
</script>
