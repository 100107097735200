<template>
  <section class="portal">
    <div class="grid grid-pad">
      <div class="col-1-2 push-1-4 sm-col-10-12 sm-push-1-12 xs-col-1-1 xs-push-0">
        <h1 class="page-title">{{ $t("Refund product") }}</h1>
        <refund-form :guid="this.guid"/>
      </div>
    </div>
  </section>
</template>

<script>
import RefundForm from '@/components/Forms/Refund.vue'

export default {
  name: 'Refund',
  props: ['guid'],
  components: {
    RefundForm
  },
  data () {
    return {}
  },
  beforeCreate () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>
